import React from 'react'

export const Navigation = (props) => {
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container-fluid">
        <div className="row mr-4">
          {/* Left side */}
          <div className="col-md-8">
            <div
              className="collapse navbar-collapse"
              id="bs-example-navbar-collapse-1"
            >
              <ul className="nav navbar-nav">
                <li>
                  <a href="#contact" className="page-scroll">
                    تـمـاس بـا مـا
                  </a>
                </li>
                <li>
                  <a href="#about" className="page-scroll">
                    دربـاره مـا
                  </a>
                </li>
                <li>
                  <a href="#team" className="page-scroll">
                    تـیــم مــا
                  </a>
                </li>

                <li>
                  <a href="#portfolio" className="page-scroll">
                    گـالــری
                  </a>
                </li>
                <li>
                  <a href="#testimonials" className="page-scroll">
                    نـظــرات
                  </a>
                </li>
                <li>
                  <a href="#features" className="page-scroll">
                    ویـژگـی هـا
                  </a>
                </li>
                <li>
                  <a href="#services" className="page-scroll">
                    خــدمــات
                  </a>
                </li>
              </ul>
            </div>
          </div>

          {/* Right side */}
          <div className="col-md-4">
            <div className="navbar-header navbar-right">
              <button
                type="button"
                className="navbar-toggle collapsed"
                data-toggle="collapse"
                data-target="#bs-example-navbar-collapse-1"
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>

              <a className="navbar-brand page-scroll" href="#page-top">
                ســـان کــدیــنــگ ایـــــران
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}
